/* userlist.css */

.user-list-container {
    margin: 20px;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
}

.user-table th,
.user-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #f2f2f2;
}

.user-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-table tbody tr:hover {
    background-color: #e5e5e5;
}

.user-table td button {
    padding: 6px 12px;
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    cursor: pointer;
}

.user-table td button:hover {
    background-color: #ff6666;
}